import Form from 'components/Form/Form';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import Input from 'components/Input';
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';

interface OrderProfileStepProps {
  onSubmit: () => void;
}
export const NavCashStep = ({ onSubmit }: OrderProfileStepProps) => {
  return (
    <Form<OrderProfileProps>
      initialValues={{}}
      onBlur={() => {}}
      onSubmit={onSubmit}
      schemaUrl={'products/schema/data=order-profile-in-kind'}
    >
      <FormGridContainer>
        <FormGridItem>
          <Input name="ticker" label="Creation Unit Size" />
        </FormGridItem>
        <FormGridItem>
          <Input name="ticker" label="Default Settlement Horizon" />
        </FormGridItem>
      </FormGridContainer>{' '}
    </Form>
  );
};
