/* eslint-disable max-lines-per-function */
import { DefaultValues } from 'react-hook-form';

// types, constants
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { GENERAL_DETAILS_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
// components
import Form from 'components/Form/Form';
import { Checkbox } from 'components/BooleanInputs';

// styles

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import Input from 'components/Input';
import { Card } from 'components/Card/Card';

interface OrderProfileStepProps {
  onSubmit: () => void;
}

export const OrderProfileInKindStep = ({ onSubmit }: OrderProfileStepProps) => {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(GENERAL_DETAILS_STEP) as OrderProfileProps;
  const saveValues = async (data: DefaultValues<OrderProfileProps>) => {
    saveInstrumentDraft({
      [GENERAL_DETAILS_STEP]: data as OrderProfileProps,
    });
  };

  return (
    <Card
      body={
        <Form<OrderProfileProps>
          initialValues={formData}
          onBlur={saveValues}
          onSubmit={onSubmit}
          schemaUrl={'products/schema/data=order-profile-in-kind'}
        >
          <FormGridContainer>
            <Checkbox name={'allowNAV+'} label="Allow In-kind Order" />
          </FormGridContainer>
          <FormGridContainer>
            <FormGridItem>
              <Input name="ticker" label="T+N Cut-off Time" />
            </FormGridItem>
            <FormGridItem>
              <Input name="ticker" label="T+0 Cut-Off Time" />
            </FormGridItem>
          </FormGridContainer>
        </Form>
      }
    />
  );
};
