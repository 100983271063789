import { isEmpty, isNumber } from 'lodash';

// types
import {
  InstrumentFormDataProps,
  InstrumentFormDataStepType,
  InstrumentFormDataType,
} from 'pages/Instruments/Instruments.types';

import {
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  EXCHANGE_STEP,
  FACTSHEET_STEP,
  DOCUMENTS_PCF_STEP,
  DOCUMENTS_FINAL_TERMS_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import {
  GeneralDetailsProps,
  MarketDetailsProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { WalletsList } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { StepsType } from 'pages/Instruments/components/Form/types';

// utils
import { getStep } from 'pages/Instruments/components/Form/utils';
import { ETPType, RebalanceStrategy } from 'utils/types/product';

// eslint-disable-next-line
function hasValue(value: any) {
  return isNumber(value) || !isEmpty(value) || typeof value === 'boolean';
}

const stepEmptinessChecker: {
  [key: string]: (values: InstrumentFormDataType | undefined) => boolean;
} = {
  [MARKET_STEP]: (values: InstrumentFormDataType | undefined) => {
    const marketInfo = values as MarketDetailsProps;
    return (
      isEmpty(marketInfo?.collateralAgent) &&
      isEmpty(marketInfo?.marketMakers) &&
      isEmpty(marketInfo?.valor)
    );
  },
  [WALLETS_INFO_STEP]: (values: InstrumentFormDataType | undefined) => {
    const walletsList = values as WalletsList;
    return isEmpty(walletsList?.custodianWallets) && isEmpty(walletsList?.unifiedWallets);
  },
};

function isStepValuesEmpty<T extends StepsType>(
  stepLabel: T,
  values: InstrumentFormDataType | undefined
): boolean {
  const emptinessChecker = stepEmptinessChecker[stepLabel] || isEmpty;

  return emptinessChecker(values);
}

function getStepStatus<
  T extends StepsType,
  DataType extends InstrumentFormDataType,
  Property extends keyof DataType
>(
  activeStep: T,
  stepLabel: InstrumentFormDataStepType,
  completedSteps: T[],
  values: DataType | undefined,
  mandatoryProps: Property[] = [],
  isDisabled: boolean = false,
  isDirty: boolean = false
) {
  const step = getStep(stepLabel);
  const isActive = stepLabel === activeStep;
  const isInvalid =
    !isStepValuesEmpty(stepLabel, values) &&
    !isEmpty(
      mandatoryProps
        .map((prop) => values && values[prop])
        .map(hasValue)
        .filter((isValidValue) => !isValidValue)
    );

  const isComplete =
    !isStepValuesEmpty(stepLabel, values) &&
    (completedSteps.includes(step.label as T) || !isInvalid);

  return { isActive, isComplete, isDisabled, isInvalid, isDirty };
}

function getAllowedDeliveriesMandatoryFields(
  allowedDeliveries?: string[]
): (keyof GeneralDetailsProps)[] | null {
  if (allowedDeliveries?.includes('CASH') && allowedDeliveries?.includes('IN-KIND')) {
    return ['adminCreationFee', 'adminRedemptionFee', 'cashCutOffTime', 'inKindCutOffTime'];
  }
  if (allowedDeliveries?.includes('CASH')) {
    return ['adminCreationFee', 'adminRedemptionFee', 'cashCutOffTime'];
  }
  if (allowedDeliveries?.includes('IN-KIND')) {
    return ['inKindCutOffTime'];
  }
  return null;
}

function getStepsStatuses<T extends StepsType>(
  instrument: InstrumentFormDataProps,
  activeStep: T,
  completedSteps: T[],
  dirtySteps: {
    [x: string]: boolean;
  }
) {
  const isIndex = instrument[GENERAL_DETAILS_STEP]?.productType === ETPType.INDEX;
  const generalDetailsStepAdditonalProps: (keyof GeneralDetailsProps)[] =
    getAllowedDeliveriesMandatoryFields(instrument[GENERAL_DETAILS_STEP]?.allowedDeliveries) ?? [];

  const isCreated = Boolean(
    instrument[GENERAL_DETAILS_STEP]?.name &&
      instrument[GENERAL_DETAILS_STEP]?.productType &&
      instrument[GENERAL_DETAILS_STEP]?.ticker
  );

  return {
    [PARTNERS_STEP]: getStepStatus(
      activeStep,
      PARTNERS_STEP,
      completedSteps,
      instrument[PARTNERS_STEP],
      [
        'authorizedParticipants',
        'calculationAgents',
        'custodians',
        'issuer',
        'pcfUploaders',
        'technicalListingAgent',
      ],
      !isCreated,
      dirtySteps[PARTNERS_STEP]
    ),
    [CUSTODIAN_INFO_STEP]: getStepStatus(
      activeStep,
      CUSTODIAN_INFO_STEP,
      completedSteps,
      instrument[CUSTODIAN_INFO_STEP],
      [],
      !isCreated,
      dirtySteps[CUSTODIAN_INFO_STEP]
    ),
    [WALLETS_INFO_STEP]: getStepStatus(
      activeStep,
      WALLETS_INFO_STEP,
      completedSteps,
      instrument[WALLETS_INFO_STEP],
      ['custodianWallets'],
      !isCreated,
      dirtySteps[WALLETS_INFO_STEP]
    ),
    [GENERAL_DETAILS_STEP]: getStepStatus(
      activeStep,
      GENERAL_DETAILS_STEP,
      completedSteps,
      instrument[GENERAL_DETAILS_STEP],
      [
        'allowedDeliveries',
        'annualFee',
        'currency',
        'productType',
        'isin',
        'jurisdiction',
        'name',
        'seriesLetter',
        'seriesName',
        'standardSettlement',
        'ticker',
        'unitSize',
        ...generalDetailsStepAdditonalProps,
      ],
      false,
      dirtySteps[GENERAL_DETAILS_STEP]
    ),
    [MARKET_STEP]: getStepStatus(
      activeStep,
      MARKET_STEP,
      completedSteps,
      instrument[MARKET_STEP],
      ['collateralAgent', 'marketMakers', 'valor'],
      !isCreated,
      dirtySteps[MARKET_STEP]
    ),
    [BENCHMARK_STEP]: getStepStatus(
      activeStep,
      BENCHMARK_STEP,
      completedSteps,
      instrument[BENCHMARK_STEP],
      instrument[BENCHMARK_STEP]?.rebalancingStrategy === RebalanceStrategy.LEVERAGED_STRATEGY ||
        instrument[BENCHMARK_STEP]?.rebalancingStrategy === RebalanceStrategy.SHORT_STRATEGY
        ? ['rebalancingFrequency', 'rebalancingStrategy']
        : [
            'indexProvider',
            'indexProviderSpecificId',
            'underlyingTicker',
            'rebalancingFrequency',
            'rebalancingStrategy',
            'underlyingCurrency',
            'underlyingName',
            'underlyingTicker',
            'dataAggregator',
          ],
      !isIndex || !isCreated,
      dirtySteps[BENCHMARK_STEP]
    ),
    [CONSTITUENT_STEP]: getStepStatus(
      activeStep,
      CONSTITUENT_STEP,
      completedSteps,
      instrument[CONSTITUENT_STEP],
      [],
      !isCreated,
      dirtySteps[CONSTITUENT_STEP]
    ),
    [EXCHANGE_STEP]: getStepStatus(
      activeStep,
      EXCHANGE_STEP,
      completedSteps,
      instrument[EXCHANGE_STEP],
      [],
      !isCreated,
      dirtySteps[EXCHANGE_STEP]
    ),
    [FACTSHEET_STEP]: getStepStatus(
      activeStep,
      FACTSHEET_STEP,
      completedSteps,
      instrument[FACTSHEET_STEP],
      [
        'ucitsEligibility',
        'lendingEligibility',
        'replicationMethod',
        'domicile',
        'legalStructure',
        'shariahCompliant',
        'registeredCountries',
      ],
      !isCreated,
      dirtySteps[FACTSHEET_STEP]
    ),
    [DOCUMENTS_PCF_STEP]: getStepStatus(
      activeStep,
      DOCUMENTS_PCF_STEP,
      completedSteps,
      instrument[DOCUMENTS_PCF_STEP],
      [],
      !isCreated,
      dirtySteps[DOCUMENTS_PCF_STEP]
    ),
    [DOCUMENTS_FINAL_TERMS_STEP]: getStepStatus(
      activeStep,
      DOCUMENTS_FINAL_TERMS_STEP,
      completedSteps,
      instrument[DOCUMENTS_FINAL_TERMS_STEP],
      [],
      !isCreated,
      dirtySteps[DOCUMENTS_FINAL_TERMS_STEP]
    ),
  };
}

export default getStepsStatuses;
