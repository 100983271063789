/* eslint-disable max-lines-per-function */
import { DefaultValues } from 'react-hook-form';

// types, constants
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import {
  GENERAL_DETAILS_STEP,
  ORDER_PROFILE_STEP_CASH_COIN_ENTITLEMENT,
  ORDER_PROFILE_STEP_CASH_NAV,
  ORDER_PROFILE_STEP_CASH_NAV_GUARANTEED,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { instrumentGeneralDetailsSchemaUrl } from 'components/Form/formSchemas';
// components
import Form from 'components/Form/Form';
import { Checkbox } from 'components/BooleanInputs';

// styles

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import Input from 'components/Input';
import { NavCashStep } from './Nav/Nav';
import { NavGuaranteedStep } from './NavGuaranteed/NavGuaranteed';
import { CoinEntitelmentStep } from './CoinEntitelment/CoinEntitelment';
import Row from 'components/Grid/Row';
import Column from 'components/Grid/Column';
import ProgressIndicator, { ProgressIndicatorProps } from 'components/ProgressIndicator';
import { useState } from 'react';
import { Card } from 'components/Card/Card';

interface OrderProfileStepProps {
  onSubmit: () => void;
}

const tab = [
  { id: ORDER_PROFILE_STEP_CASH_NAV, label: ORDER_PROFILE_STEP_CASH_NAV, component: NavCashStep },
  {
    id: ORDER_PROFILE_STEP_CASH_NAV_GUARANTEED,
    label: ORDER_PROFILE_STEP_CASH_NAV_GUARANTEED,
    component: NavGuaranteedStep,
  },
  {
    id: ORDER_PROFILE_STEP_CASH_COIN_ENTITLEMENT,
    label: ORDER_PROFILE_STEP_CASH_COIN_ENTITLEMENT,
    component: CoinEntitelmentStep,
  },
] as const;

type TabType = (typeof tab)[number]['id'];

export const OrderProfileCashStep = ({ onSubmit }: OrderProfileStepProps) => {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(GENERAL_DETAILS_STEP) as OrderProfileProps;
  const saveValues = async (data: DefaultValues<OrderProfileProps>) => {
    saveInstrumentDraft({
      [GENERAL_DETAILS_STEP]: data as OrderProfileProps,
    });
  };

  const [orderProfileCashActiveStep, setOrderProfileCashActiveStep] = useState(
    ORDER_PROFILE_STEP_CASH_NAV
  );

  const progress: ProgressIndicatorProps<TabType>['steps'] = tab.map(({ id, label }) => ({
    id,
    label,
    isActive: id === (orderProfileCashActiveStep as TabType),
    isDisabled: label !== 'Nav',
  }));

  const Tab = tab.find(({ id }) => id === orderProfileCashActiveStep)!.component;

  return (
    <Card
      body={
        <Form<OrderProfileProps>
          initialValues={formData}
          onBlur={saveValues}
          onSubmit={onSubmit}
          schemaUrl={'products/schema/data=order-profile-cash'}
        >
          <FormGridContainer>
            <Checkbox name={'allowNAV+'} label="Allow Cash Order" />
          </FormGridContainer>
          <FormGridContainer>
            <FormGridItem>
              <Input name="ticker" label="Allowed Delivery Currencies" />
            </FormGridItem>
            <FormGridItem>
              <Input name="ticker" label="Allowed Settlement Horizons" />
            </FormGridItem>
          </FormGridContainer>
          <Card
            body={
              <Form<OrderProfileProps>
                initialValues={formData}
                onBlur={saveValues}
                onSubmit={onSubmit}
                schemaUrl={instrumentGeneralDetailsSchemaUrl}
              >
                <Row>
                  <Column>
                    <ProgressIndicator
                      fitContent
                      noIcon
                      onClickStep={(step) => setOrderProfileCashActiveStep(step)}
                      steps={progress}
                    />
                  </Column>
                </Row>
                <Tab key={orderProfileCashActiveStep} onSubmit={onSubmit} />
              </Form>
            }
          />
        </Form>
      }
    />
  );
};
